import React from "react"
import Layout from "../components/layout"
import Article from "../components/article"
import SEO from "../components/seo"
import FriendLink from "../components/friendlink"

export default ({pageContext: { item, list }}) => (
   <Layout>
       <SEO title={item.title}/>
       <Article 
            articleTitle={item.title}
            articleImgs={item.imgs}
            articleId={item.id}
            articleList={list}
       />
       <FriendLink urlType="global" />
   </Layout>

)