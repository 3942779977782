import React from "react"
import Item from "./item"
import Style from "../styles/article.module.css"
import axios from "axios"
import LazyLoad from 'react-lazy-load'
import { Link } from "gatsby"

function ImgItem (props) {
    return (
        <div className={Style.imageItem}>
            <img 
            onError={(e) => {e.target.onerror = null;e.target.src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}}
            src={props.itemUrl} 
            alt={props.itemTitle}/>
            <a href={props.itemUrl} target="_blank" rel='noreferrer noopener'><i></i></a>
        </div>
    )
}

const RightBox = (props) => (
    <div className={Style.right}>
    <h2>{props.itemTitle}</h2>
    <div className={Style.abstract}>
        <span><em>{props.itemIndex + 1}</em>/{props.itemLen}</span>
    </div>
</div>
)

const LeftBox = (props) => (
   
    <div 
        role="textbox"
        tabIndex="0"
        className={`${Style.left} ${props.imgBoxClass}`} 
        onClick={(e) => props.itemClick(e)}
        onKeyDown={(e) => props.itemClick(e)}
        onMouseMove={(e) => props.itemMouseMove(e)}
    >
        {
            props.imgs.map((img,index) => {
                if (index === props.itemIndex) {
                   return <ImgItem key={index} itemUrl={img} itemTitle={props.itemTitle} />
                }
                return null;
            })
        }
    </div>
)

const Box = (props) => (
    <div className={Style.box}>
    <LeftBox 
        imgBoxClass={props.imgBoxClass}
        itemMouseMove={props.itemMouseMove}
        itemClick={props.itemClick}
        imgs={props.imgs}
        itemIndex={props.itemIndex}
        itemTitle={props.itemTitle}
    />
    <RightBox 
        itemTitle={props.itemTitle}
        itemIndex={props.itemIndex}
        itemLen={props.itemLen}
    />
</div>
)

const LastPageBox = (props) => (
    <div className={`${Style.box} ${Style.paddingBox}`}>
        <div className={Style.lastPageAction}>
            <h2>{props.itemTitle}</h2>
            <a href={`/pic/${props.nextId}`} className={Style.nextPage}>下一图集</a>
            <button onClick={props.resetPage} className={Style.reset}>重新浏览</button>
        </div>
        <div className={Style.lastPageItems}>
            {
                props.aboutData.map((item,index) => {
                    if (index <= 7){
                       return ( <Item 
                            key={index}
                            itemWidth="24%"
                            itemImgHeight="170px"
                            itemId={item.id}
                            itemTitle={item.title}
                            itemSrc={item.imgs[0]}
                            itemLen={item.imgs.length}
                        />)
                    }
                    return null
                })
            }
        </div>
    </div>
)

const AboutBox = (props) => (
    <div className={Style.aboutBox}>
        <h2>相关推荐</h2>
        <div className={Style.aboutImgs}>
        {
            props.aboutData.map((item,index) => {
                if (index > 7) {
                    return (
                        <Item 
                            key={index}
                            itemImgHeight="180px"
                            itemId={item.id}
                            itemTitle={item.title}
                            itemSrc={item.imgs[0]}
                            itemLen={item.imgs.length}
                        />
                    )
                }
                return null;
            })
        }
        </div>
    </div>
)

const colour = ['#a7549a','#f86927','#c89b40','#317ef3','#fe8081','#f8cc89','#386ba0','#010101','#a88464',
'#bf242c','#8f75a4','#88dfc5','#f88fd7','#b6a6ed','#f3eaed','#d1a2d9'];

const SmallScreenBox = (props) => (
    <article>
        <h2>{props.itemTitle}</h2>
        {
            props.imgs.map((img,index) => (
                <div style={{
                    background: colour[Math.floor((Math.random()*colour.length))],
                    width:`100%`,
                    display:`flex`,
                    marginBottom: `5px`
                    }} key={index}>
                    <LazyLoad width="100%">
                        <img 
                        onError={(e) => {e.target.onerror = null;e.target.src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}}
                        src={img} 
                        alt={props.itemTitle}/>
                    </LazyLoad>
                </div>
            ))
        }
    </article>
)

class Imgs extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.ids = [];
        this.state = {
            index: 0,
            title: props.articleTitle,
            imgs: props.articleImgs,
            id: props.articleId,
            aboutData: [],
            imgBoxClass: "",
            isStart: false
        }
    }

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener("keyup", (e) => this.handelKeyUp(e));
        let sum = this.props.articleList.sum;
        let exclude = this.props.articleList.exclude;
        this.createIds(sum, exclude);
        this.isStart();
        this.count(2);
    }

    count(action) {
        axios({
            method: "POST",
            url: "https://api.like-gif.com",
            data: {
                action: action,
                obj: this.state.id,
                app: 2
            },
            transformRequest: [function(data) {
                let ret = ''
                for(let it in data) {
                    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
                }
                return ret
            }],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }

    createIds(sum, exclude) {
        let ids = [];
        for(let i = 1; i <= sum; i++) {
            if(!exclude.includes(i)) {
                ids.push(i);
            }
        }
        this.ids = ids;
        let list = this.getLst(14);
        this.insertLst(list);
    }

    insertLst(arr) {
        let items = this.state.aboutData;
        arr.forEach(element => {
            axios.get(`/page-data/pic/${element}/page-data.json`) 
            .then((res) => {
                items.push(res.data.result.pageContext.item);
                this.setState({
                    aboutData: items
                })
            })
        });
    }
    
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("keyup", (e) => this.handelKeyUp(e));
    }

    handelKeyUp (e) {
        if (this._isMounted){
            let index = this.state.index;
            let len = this.state.imgs.length;
            if (index >= len) {
                return false;
            }
            if (e.keyCode === 39) {
                index++
            } 
            if (e.keyCode === 37) {
                if (index === 0) {
                    index = len - 1;
                } else {
                    index--
                }
            }
            this.setState({
                index:index
            })
        }
        
    }

    handleClick = (event) => {
        if (this._isMounted){
            let index = this.state.index;
            let len = this.state.imgs.length;
            let width = document.querySelector('body').offsetWidth;
            let e = event || window.event;
            let scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
            let x = e.pageX || e.clientX + scrollX;
            let haleWidth = (width - 1000) / 2 + 330;
            if (x <= haleWidth) {
                if (index === 0) {
                   index = len - 1;
                }else{
                    index--;
                }
            } else {
                index++;
            }
            this.setState({
                index:index
            })
        }
    }

    goTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    handleMouseMove = (event) => {
        if (this._isMounted){
            event.stopPropagation();
            let className = this.state.imgBoxClass;
            let width = document.querySelector('body').offsetWidth;
            let e = event || window.event;
            let scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
            let x = e.pageX || e.clientX + scrollX;
            let haleWidth = (width - 1000) / 2 + 330;
            if (x <= haleWidth) {
                className = Style.imgboxLeft;
            } else {
                className = Style.imgboxRight;
            }
            if (className !== this.state.imgBoxClass) {
                this.setState({
                    imgBoxClass: className
                })
            }
            if (className !== this.state.imgBoxClass) {
                this.setState({
                    imgBoxClass: className
    
                })
            }
        }
    }

    resetPage = () => {
        this.setState({
            index: 0
        })
    }

    getNums() {
        let s = Math.floor(Math.random() * this.ids.length);
        let m = this.ids[s];
        this.ids.splice(s,1);
        return m;
    }

    getLst(num) {
        let i = 0
        let list = [];
        while(i < num) {
            list.push(this.getNums());
            i++;
        }
        return list;
    }

    isStart() {
        let start = localStorage.getItem(`pic_${this.state.id}`);
        this.setState({
            isStart: start === null
        })
    }

    startAction() {
        let obj_id = this.state.id;
        let key = `pic_${obj_id}`;
        let start = localStorage.getItem(key);
        if(start === null){
            let obj = {
                title: this.state.title,
                img: this.state.imgs[0],
                id: obj_id,
                num: this.state.imgs.length
            }
            try{
                localStorage.setItem(key, JSON.stringify(obj));
            } catch(e) {
                alert('不能太贪心哦，不能再收藏了！');
            }
            this.count(1);
        } else {
            localStorage.removeItem(key);
        }
        this.isStart();
    }

    render () {
        return (
            <div>
                <div className={Style.bigScreen}>
                    {
                        this.state.index < this.state.imgs.length ? 
                        <Box
                            imgBoxClass={this.state.imgBoxClass}
                            itemMouseMove={this.handleMouseMove}
                            itemClick={this.handleClick}
                            imgs={this.state.imgs}
                            itemIndex={this.state.index}
                            itemTitle={this.state.title}
                            itemLen={this.state.imgs.length}
                        />
                        :
                        this.ids.length > 0 ? 
                        <LastPageBox 
                            itemTitle={this.state.title}
                            nextId={this.state.nextId}
                            itemId={this.state.id}
                            aboutData={this.state.aboutData}
                            resetPage={this.resetPage}
                            nextId={this.getNums()}
                        />
                        :
                        false
                    }
                    </div>
                    <div className={Style.smallScreen}>
                        <SmallScreenBox 
                            itemId={this.state.id}
                            itemTitle={this.state.title}
                            imgs={this.state.imgs}
                            itemIndex={this.state.index}
                            itemLen={this.state.imgs.length}
                            nextId={this.state.nextId}
                        />
                        {this.ids.length > 0 ? <a className={Style.btn} href={`/pic/${this.getNums()}`}>换一个</a>:false}
                    </div>
                <AboutBox aboutData={this.state.aboutData} />
                <button className={this.state.isStart ? Style.isStart : Style.noStart} onClick={() => this.startAction()}>&nbsp;</button>
                <button className={Style.goTop} onClick={this.goTop}>&nbsp;</button>
                <Link to="/" className={Style.goHome}>&nbsp;</Link>
            </div>
        )
    }
}

export default Imgs